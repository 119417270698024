<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'tampungan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'tampungan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'tampungan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3">

      <b-modal
        id="modal-form"
        v-model="showModal"
        title="Cek Koordinat Sungai"
        @hide="resetForm"
        @shown="copyCoordinateToModal"
      >
	  	<div>
          <l-map
            ref="leafletMap"
            :zoom="13"
            :center="[latitude, longitude]"
            style="height: 300px; width: 100%"
          >
            <div>
              <l-tile-layer
                :visible="tileProviders[0].visible"
                :url="tileProviders[0].url"
                :attribution="tileProviders[0].attribution"
              />
              <l-tile-layer
                :visible="tileProviders[1].visible"
                :url="tileProviders[1].url"
                :attribution="tileProviders[1].attribution"
              />
            </div>

            <!-- <l-tile-layer :url="tileLayer" :attribution="attribution" /> -->
            <!-- Draggable Marker -->
            <l-marker
              :lat-lng="[latitude, longitude]"
              :icon="icon" 
              :draggable="false"
            />
          </l-map>
		</div>
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <!-- Latitude Input -->
            <div class="col-md-6">
              <b-form-group label="Latitude" label-for="latitude">
                <b-form-input
                  id="latitude"
                  v-model="latitude"
                  required
                  placeholder="Enter latitude"
                  type="text"
                  step="any"
				  @keyup="checkFormatLatitudeLongotude('latitude')"
                ></b-form-input>
              </b-form-group>
            </div>

            <!-- Longitude Input -->
            <div class="col-md-6">
              <b-form-group label="Longitude" label-for="longitude">
                <b-form-input
                  id="longitude"
                  v-model="longitude"
                  required
                  placeholder="Enter longitude"
                  type="text"
                  step="any"
				  @keyup="checkFormatLatitudeLongotude('longitude')"
                ></b-form-input>
              </b-form-group>
            </div>

			<div class="col-md-12">
              <b-form-group label="Latitude" label-for="Kelompok Data Dasar">
                <b-form-select
                  id="latitude"
                  v-model="kelompok_data_dasar"
                  :options="optionsKDS"
                  required
                  placeholder="Pilih jenis"
				></b-form-select>
              </b-form-group>
            </div>
          </div>

          <!-- Submit Button -->
          <b-button type="submit" variant="primary">Check</b-button>
        </b-form>

        <div v-if="loadingCoordinate" class="mt-4">
          <p>Loading data...</p>
        </div>

        <!-- Response Section -->
        <div v-if="checkStatus">
          <div v-if="responseCoordinate.length > 0" class="mt-4">
            <h5>Hasil Pengecekan</h5>
            <div v-for="(item, index) in responseCoordinate" :key="index">
              <b-form-group :label="'sumber: ' + item.source + ', jarak: ' + item.distance + ' meter'">
                <b-input-group>
                  <b-form-input :value="item.name" readonly></b-form-input>
                  <b-input-group-append>
                    <b-button
                      @click="copyToClipboard(item.name)"
                      variant="outline-secondary"
                    >
                      Copy
                    </b-button>
                    <b-button
                      @click="useInfrastructure(item.name)"
                      variant="outline-primary"
                    >
                      Gunakan
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div v-else class="mt-4">
            <p>Data tidak ditemukan.</p>
          </div>
        </div>
      </b-modal>

      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-field-data-changed="onFormFieldDataChanged"
        :on-form-add-new="formOnAddNew"
        :on-form-before-save="formBeforeSave"
        :form-clone-mode="formCloneMode"
        :has-upload-files="true">

        <div slot="additionalForm">
          <dx-tab-panel
            ref="tabPanel"
            :items="[{title:'Foto', template: 'tab1'}]"
            :selected-index="0"
            :animation-enabled="true"
            :element-attr="{class:'mt-1 mb-1'}">
            <template #title="{ data: tabs }">
              <span>
                {{ tabs.title }}
              </span>
            </template>
            <template slot="tab1">
              <div class="containers">
                <!-- <div v-show="fileUploads.length === 0"> -->
                <dx-file-uploader
                  ref="fileUploader"
                  :on-value-changed="filesValueChanged"
                  :multiple="true"
                  :show-file-list="false"
                  select-button-text="Pilih File"
                  label-text=""
                  accept="image/*"
                  upload-mode="useForm"/>
                <!-- </div> -->

                <dx-tile-view
                  ref="tileView"
                  :items="fileUploads"
                  :height="206"
                  :base-item-height="50"
                  :base-item-width="500"
                  :item-margin="10"
                  direction="vertical"
                  no-data-text="">
                  <div
                    slot="item"
                    slot-scope="data">
                    <div class="price text-truncate">
                      {{ data.data.filename }}
                    </div>
                    <!-- <div
                      :id="`${formName}Foto-${data.id}`"
                      class="image"/> -->
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      size="sm"
                      @click.prevent.stop="filesDel(data.data)">
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50" />
                    </b-button>
                  </div>
                </dx-tile-view>
              </div>
            </template>
          </dx-tab-panel>
        </div>
      </form-app>
    </div>
    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>
  </div>
</template>

<script>
import { DxTabPanel } from 'devextreme-vue/ui/tab-panel'
import { DxFileUploader } from 'devextreme-vue/ui/file-uploader'
import { DxTileView } from 'devextreme-vue/ui/tile-view'
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'
import axiosIns from "@/libs/axios";
import {LMap, LTileLayer, LMarker, LIcon} from 'vue2-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BButton,
  BFormSelect
} from "bootstrap-vue";

const _ = require('lodash')

export default {
  components: {
    DxTabPanel,
    DxFileUploader,
    DxTileView,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    BFormSelect,
	LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  props: {
    pageName: {
      type: String,
      default: 'Tampungan'
    },
    title: {
      type: String,
      default: 'Tampungan'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 200
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'tampunganDelBatch',
      restoreRowsRoute: 'tampunganRestoreBatch',
      fileUploads: [],
	  latitude: "",
      longitude: "",
	  kelompok_data_dasar: "",
      responseCoordinate: [],
      showModal: this.formDataId ? false : true,
      checkStatus: false,
      loadingCoordinate: false,
      optionsKDS: [
        { value: null, text: 'Pilih jenis' },  // Placeholder option
        { value: 'Waduk', text: 'Waduk' },
        { value: 'Situ', text: 'Situ' },
        { value: 'Danau', text: 'Danau' },
        { value: 'Embung', text: 'Embung' }
      ],
	  tileLayer: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "OpenTopoMap",
          visible: false,
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
      ],
      icon: L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  		shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		shadowSize: [41, 41],
		shadowAnchor: [12, 41]
      }),
    })
    return data
  },
  created() {
    const vm = this
    vm.gridCreated()

    vm.$events.$on(`${vm.formName}FormMountedEvent`, () => {
      const formRef = vm.$refs[vm.formName]
      if (formRef === undefined) {
        return false
      }

      if (formRef.formDataEdit !== null) {
        formRef.form.getEditor('kota_id').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', formRef.formDataEdit.provinsi_id]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })

        formRef.form.getEditor('kecamatan_id').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', formRef.formDataEdit.kota_id]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })

        formRef.form.getEditor('kelurahan_id').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kelurahan.id',
              loadUrl: 'api/data/kelurahan',
              loadParams: {
                select: [
                  'kelurahan.id',
                  'kelurahan.nama'
                ],
                where: [['kelurahan.id_kecamatan', '=', formRef.formDataEdit.kecamatan_id]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })
      }
    })
  },
  mounted() {
    const vm = this
    vm.gridMount()
    vm.formMount()
  },
  methods: {
	  handleBtnCheckCoordinate() {
      this.showModal = true;
    },
    copyCoordinateToModal() {
      this.latitude = document.querySelector('input[name="lat"]').value;
      this.longitude = document.querySelector('input[name="lng"]').value;
      this.kelompok_data_dasar = document.querySelector('input[name="kelompok_data_dasar"]').value;

	  this.checkFormatLatitudeLongotude('latitude');
      this.checkFormatLatitudeLongotude('longitude');

      this.$nextTick(() => {
        if (this.$refs.leafletMap && this.$refs.leafletMap.mapObject) {
          this.$refs.leafletMap.mapObject.invalidateSize();
        }
      });
    },
    checkFormatLatitudeLongotude(field)
	{
      const regex = /^-?\d*\.?\d*$/; // Hanya angka, tanda minus di depan, dan satu titik desimal

	  if (field === 'latitude') {
        let sanitizedValue = this.latitude.replace(',', '.');
        if (regex.test(sanitizedValue)) {
          this.latitude = sanitizedValue;
        } else {
          this.latitude = this.latitude.slice(0, -1);
        }
      } else if (field === 'longitude') {
        let sanitizedValue = this.longitude.replace(',', '.');
        if (regex.test(sanitizedValue)) {
          this.longitude = sanitizedValue;
        } else {
          this.longitude = this.longitude.slice(0, -1);
        }
      }
	},
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = parseInt(command.rowData.id)
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/tampungan/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const vm = this.$refs[this.formName]
      if (e.value === undefined || !vm.formItemsSetStatus) {
        return false
      }
      const { formDataEdit } = vm

      if (e.dataField === 'balai_id') {
        vm.form.getEditor('ws_id').option({
          value: formDataEdit !== null ? formDataEdit.ws_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'master_ws.id',
              loadUrl: 'api/data/wilayahSungai',
              loadParams: {
                select: [
                  'master_ws.id',
                  'master_ws.nama'
                ],
                where: [['master_ws.balai_id', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['master_ws.nama']
          }
        })
      }

      if (e.dataField === 'ws_id') {
        vm.form.getEditor('das_id').option({
          value: formDataEdit !== null ? formDataEdit.das_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'master_das.id',
              loadUrl: 'api/data/daerahAliranSungai',
              loadParams: {
                select: [
                  'master_das.id',
                  'master_das.nama'
                ],
                where: [['master_das.ws_id', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['master_das.nama']
          }
        })
      }

      // lokasi
      if (e.dataField === 'provinsi_id') {
        vm.form.getEditor('kota_id').option({
          value: formDataEdit !== null ? formDataEdit.kota_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kota.nama']
          }
        })
      }

      if (e.dataField === 'kota_id') {
        vm.form.getEditor('kecamatan_id').option({
          value: formDataEdit !== null ? formDataEdit.kecamatan_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kecamatan.nama']
          }
        })
      }

      if (e.dataField === 'kecamatan_id') {
        vm.form.getEditor('kelurahan_id').option({
          value: formDataEdit !== null ? formDataEdit.kelurahan_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kelurahan.id',
              loadUrl: 'api/data/kelurahan',
              loadParams: {
                select: [
                  'kelurahan.id',
                  'kelurahan.nama'
                ],
                where: [['kelurahan.id_kecamatan', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kelurahan.nama']
          }
        })
      }

      // daerah layanan 2
      if (e.dataField === 'dl_prov_id') {
        vm.form.getEditor('dl_kota_id').option({
          value: formDataEdit !== null ? formDataEdit.dl_kota_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kota.nama']
          }
        })
      }

      if (e.dataField === 'dl_kota_id') {
        vm.form.getEditor('dl_kec_id').option({
          value: formDataEdit !== null ? formDataEdit.dl_kec_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kecamatan.nama']
          }
        })
      }

      if (e.dataField === 'dl_kec_id') {
        vm.form.getEditor('dl_kel_id').option({
          value: formDataEdit !== null ? formDataEdit.dl_kel_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kelurahan.id',
              loadUrl: 'api/data/kelurahan',
              loadParams: {
                select: [
                  'kelurahan.id',
                  'kelurahan.nama'
                ],
                where: [['kelurahan.id_kecamatan', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kelurahan.nama']
          }
        })
      }

      // // daerah layanan
      // if (e.dataField === 'detail.manfaat.provinsi') {
      //   vm.form.getEditor('detail.manfaat.kota').option({
      //     value: formDataEdit !== null ? formDataEdit.detail.manfaat.kota : null,
      //     dataSource: {
      //       store: vm.$aspnet.createStore({
      //         key: 'kota.id',
      //         loadUrl: 'api/data/kota',
      //         loadParams: {
      //           select: [
      //             'kota.id',
      //             'kota.nama'
      //           ],
      //           where: [['kota.id_provinsi', '=', e.value !== null ? e.value : 0]]
      //         },
      //         onBeforeSend(operation, ajaxSettings) {
      //           if (operation === 'load') {
      //             ajaxSettings.headers = {
      //               Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      //             }
      //             ajaxSettings.data = {
      //               data: JSON.stringify(ajaxSettings.data)
      //             }
      //           }
      //         }
      //       }),
      //       paginate: true,
      //       pageSize: 10,
      //       sort: ['kota.nama']
      //     }
      //   })
      // }

      // if (e.dataField === 'detail.manfaat.kota') {
      //   vm.form.getEditor('detail.manfaat.kecamatan').option({
      //     value: formDataEdit !== null ? formDataEdit.detail.manfaat.kecamatan : null,
      //     dataSource: {
      //       store: vm.$aspnet.createStore({
      //         key: 'kecamatan.id',
      //         loadUrl: 'api/data/kecamatan',
      //         loadParams: {
      //           select: [
      //             'kecamatan.id',
      //             'kecamatan.nama'
      //           ],
      //           where: [['kecamatan.id_kota', '=', e.value !== null ? e.value : 0]]
      //         },
      //         onBeforeSend(operation, ajaxSettings) {
      //           if (operation === 'load') {
      //             ajaxSettings.headers = {
      //               Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      //             }
      //             ajaxSettings.data = {
      //               data: JSON.stringify(ajaxSettings.data)
      //             }
      //           }
      //         }
      //       }),
      //       paginate: true,
      //       pageSize: 10,
      //       sort: ['kecamatan.nama']
      //     }
      //   })
      // }

      // if (e.dataField === 'detail.manfaat.kecamatan') {
      //   vm.form.getEditor('detail.manfaat.kelurahan').option({
      //     value: formDataEdit !== null ? formDataEdit.detail.manfaat.kelurahan : null,
      //     dataSource: {
      //       store: vm.$aspnet.createStore({
      //         key: 'kelurahan.id',
      //         loadUrl: 'api/data/kelurahan',
      //         loadParams: {
      //           select: [
      //             'kelurahan.id',
      //             'kelurahan.nama'
      //           ],
      //           where: [['kelurahan.id_kecamatan', '=', e.value !== null ? e.value : 0]]
      //         },
      //         onBeforeSend(operation, ajaxSettings) {
      //           if (operation === 'load') {
      //             ajaxSettings.headers = {
      //               Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      //             }
      //             ajaxSettings.data = {
      //               data: JSON.stringify(ajaxSettings.data)
      //             }
      //           }
      //         }
      //       }),
      //       paginate: true,
      //       pageSize: 10,
      //       sort: ['kelurahan.nama']
      //     }
      //   })
      // }
    },
    getProvinsi() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.provinsi] : null
    },
    getKota() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kota] : null
    },
    getKecamatan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kecamatan] : null
    },
    getKelurahan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kelurahan] : null
    },
    getBalai() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.balai] : null
    },
    getWilayahSungai() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.wilayah_sungai] : null
    },
    getDaerahAliranSungai() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.daerah_aliran_sungai] : null
    },
    getDlProvinsi() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.provinsi] : null
    },
    getDlKota() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kota] : null
    },
    getDlKecamatan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kecamatan] : null
    },
    getDlKelurahan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kelurahan] : null
    },
    formMount() {
      const vm = this
      vm.$events.$on(`${vm.formName}FormMountedEvent`, () => {
        console.log(`${vm.formName}FormMountedEvent`)
        vm.fileUploads = []
        const { formDataEdit } = this.$refs[this.formName]
        if (formDataEdit !== undefined && formDataEdit !== null) {
          const realisasiDokumen = formDataEdit.realisasi_dokumen
          _.each(realisasiDokumen, (val, index) => {
            vm.fileUploads.push({
              id: index,
              file: null,
              filename: val.filename_ori
            })
          })
        }
      })
    },
    formBeforeSave() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]

      return new Promise(done => {
        const fd = new FormData()

        _.each(formData, (val, key) => {
          if (key === 'detail') {
            fd.append(key, JSON.stringify(val))
          } else {
            fd.append(key, val)
          }
        })

        _.each(vm.fileUploads, (val, i) => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append(`files[${ i }]`, val.file)
            }
          }
        })

        vm.$refs[vm.formName].formDataHasUploadFiles = fd
        done(true)
      }).catch(error => {
        this.msgShow(error, 'error')
        return false
      })
    },
    filesValueChanged(e) {
      const vm = this
      _.each(e.value, (val, index) => {
        vm.fileUploads.push({
          id: index,
          file: val,
          filename: val.name
        })
        console.log('vm.fileUploads', vm.fileUploads)
        // const reader = new FileReader()
        // reader.onload = ev => {
        //   // console.log(ev.target.result)
        //   // $(`#${vm.formName}Foto-${index}`).attr('style', `background-image : url(${e.target.result})`)
        // }
        // reader.readAsDataURL(val)
      })
      // vm.$refs.tabPanel.instance.repaint()
    },
    filesDel(data) {
      const vm = this
      vm.fileUploads = _.filter(vm.fileUploads, currentObject => currentObject.id !== data.id)
    },
	resetForm() {
      this.latitude = "";
      this.longitude = "";
      this.kelompok_data_dasar = null;
      this.responseCoordinate = [];
      this.checkStatus = false;
    },

    // Handle form submission
    async submitForm() {
      this.checkStatus = false;
      try {
        this.loadingCoordinate = true;
		this.checkStatus = false;
        const response = await axiosIns.get(
          "/v2/public/check-coordinate/tampungan",
          {
            params: {
              latitude: this.latitude,
              longitude: this.longitude,
              kelompok_data_dasar: this.kelompok_data_dasar
            },
          }
        );
        if (response.data.status === "success") {
          this.responseCoordinate = response.data.data;
          this.checkStatus = true;
        }
        this.loadingCoordinate = false;

        document.querySelector('input[name="lat"]').value = this.latitude;
        document.querySelector('input[name="lng"]').value = this.longitude;
        this.$refs[this.formName].formDataEdit.tahun_pengadaan = this.kelompok_data_dasar;

      } catch (error) {
        console.error("Error fetching data from API:", error);
        this.loadingCoordinate = false;
      }
    },

    // Copy text to clipboard
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          alert("Copied to clipboard: " + text);
        },
        () => {
          alert("Failed to copy text");
        }
      );
    },
    useInfrastructure(value) {
      // Mencari input dengan name 'nama_sumur_new' dan mengisi value-nya
      const nama_sungai = document.querySelector('input[name="nama_sungai"]');
      if (nama_sungai) {
        nama_sungai.value = value;
        alert("Nama sumur berhasil digunakan: " + value);
      }
    }
  }
}
</script>

<style>
.dx-form-group-content {
  padding-top: 10px !important;
}
.dx-datagrid .dx-column-lines > td {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.dx-row-lines > td {
  background-color: rgba(191, 191, 191, 0.15);
}
</style>
